<template>
  <router-view />
</template>

<script>
import { onUnmounted } from '@vue/composition-api'
import config from './config'

export default {
  name: 'Service',
  setup() {
    const {
      MODULE_NAME, store, model, MODULE_NAME_ATTACHMENT, modelAttachment,
    } = config()

    // Register on enter section
    if (!store.hasModule(MODULE_NAME)) store.registerModule(MODULE_NAME, model)
    if (!store.hasModule(MODULE_NAME_ATTACHMENT)) store.registerModule(MODULE_NAME_ATTACHMENT, modelAttachment)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODULE_NAME)) store.unregisterModule(MODULE_NAME)
      if (store.hasModule(MODULE_NAME_ATTACHMENT)) store.unregisterModule(MODULE_NAME_ATTACHMENT)
    })
  },
}

</script>
